import { FC, Fragment, useState } from 'react';
import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
// component
import DataLoader from 'components/common/DataLoader';
// constants, interfaces, graphql
import { TFormulaLoading } from 'interfaces';
import { MacroNutrientsWeights } from 'generated/graphql';
import { NO_NUTRIENTS_FOUND_TEXT } from 'constants/index';
import FormulaNutrientsWeightsCard from './NutrientsWeightsCard';

const FormulaIngredientNutrients: FC<TFormulaLoading> = ({ costing, loading }) => {
  const { formulaIngredients } = costing || {};

  const [itemOpenState, setItemOpenState] = useState(Array(formulaIngredients?.length)?.fill(false));

  const handleItemClick = (index: number) => {
    const newOpenState = [...itemOpenState];
    newOpenState[index] = !newOpenState[index];
    setItemOpenState(newOpenState);
  };
  const areAllValuesZero = (obj: MacroNutrientsWeights) => {
    return Object.values(obj).every((value) => value === 0);
  };
  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        formulaIngredients?.map((item, index) => {
          const { macroNutrientsWeight, id, ingredient } = item || {};
          const { name } = ingredient || {};

          return (
            <Fragment key={id}>
              <Box
                flex={1}
                paddingX={2}
                paddingY={1}
                mt={1.5}
                display="flex"
                borderRadius={1}
                alignItems="center"
                bgcolor={'primary.main'}
                onClick={() => handleItemClick(index)}>
                <Typography variant="caption" fontWeight={700} flex={1}>
                  {name}
                </Typography>

                <IconButton aria-label="expand row" size="small">
                  {itemOpenState[index] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
              </Box>

              <Collapse in={itemOpenState[index]} timeout="auto" unmountOnExit>
                {macroNutrientsWeight && !areAllValuesZero(macroNutrientsWeight) ? (
                  <FormulaNutrientsWeightsCard key={id} macroNutrientsWeight={macroNutrientsWeight} />
                ) : (
                  <Typography variant="body2" padding={2} fontWeight="bold">
                    {`${NO_NUTRIENTS_FOUND_TEXT}`}
                  </Typography>
                )}
              </Collapse>
            </Fragment>
          );
        })
      )}
    </Fragment>
  );
};

export default FormulaIngredientNutrients;
