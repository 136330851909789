import { FC, Fragment } from 'react';
// component
import RowView from 'components/common/RowView';
// constants, interfaces
import { MACRO_NUTRIENT_TEXTS } from 'constants/index';
import { FormulaNutrientWeightCardProps } from 'interfaces';

const FormulaNutrientsWeightsCard: FC<FormulaNutrientWeightCardProps> = ({ macroNutrientsWeight }) => {
  // calculate weights of the macro nutrients

  const {
    protein,
    totalFat,
    calories,
    addedSugar,
    totalSugars,
    cholesterol,
    saturatedFat,
    dietaryFiber,
    carbohydrates,
  } = macroNutrientsWeight || {};

  return (
    <Fragment>
      <RowView name={MACRO_NUTRIENT_TEXTS.calories} value={calories ? `${calories}` : ''} />
      <RowView name={`${MACRO_NUTRIENT_TEXTS.totalFat}`} value={totalFat ? `${totalFat} g` : ''} />
      <RowView
        name={`${MACRO_NUTRIENT_TEXTS.saturatedFat}`}
        value={saturatedFat ? `${saturatedFat} g` : ''}
      />
      <RowView name={MACRO_NUTRIENT_TEXTS.cholesterol} value={cholesterol ? `${cholesterol} g` : ''} />
      <RowView name={MACRO_NUTRIENT_TEXTS.carbohydrates} value={carbohydrates ? `${carbohydrates} g` : ''} />
      <RowView
        name={`${MACRO_NUTRIENT_TEXTS.dietaryFiber}`}
        value={dietaryFiber ? `${dietaryFiber} g` : ''}
      />
      <RowView name={MACRO_NUTRIENT_TEXTS.totalSugars} value={totalSugars ? `${totalSugars} g` : ''} />
      <RowView name={MACRO_NUTRIENT_TEXTS.addedSugar} value={addedSugar ? `${addedSugar} g` : ''} />
      <RowView name={MACRO_NUTRIENT_TEXTS.protein} value={protein ? `${protein} g` : ''} />
    </Fragment>
  );
};

export default FormulaNutrientsWeightsCard;
