import { FC, Fragment, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, Typography, AutocompleteChangeReason, Card, Grid } from '@mui/material';
// components
import AddBlendModal from './AddBlendModal';
import { Alert } from 'components/common/Alert';
import IngredientsCard from './IngredientsCard';
import IngredientBlends from './IngredientBlends';
import NoIngredientFound from './NoIngredientFound';
import CategorySelector from 'components/common/selectors/category';
import IngredientSelector from 'components/common/selectors/ingredients';
// interfaces, constants, styles, context
import {
  VIEW_TEXT,
  SEARCH_TEXT,
  ADD_BLEND_TEXT,
  FACT_SHEET_TEXT,
  INGREDIENTS_TEXT,
  PRIMARY_CATEGORY_TEXT,
  SECONDARY_CATEGORY_TEXT,
  INGREDIENT_ALREADY_EXIST_TEX,
} from 'constants/index';
import { displayFlexAlignCenter } from 'theme/styleConstant';
import { flexCenterBetween, forInputLabelBg } from 'styles/commonComponentStyle';
import { AddNewFormulaFormType, IngredientItemType, IngredientsListProps, SelectType } from 'interfaces';

const IngredientsList: FC<IngredientsListProps> = ({
  xl,
  onBlendAdd,
  ingredientsBlend,
  isFactBox = false,
  formulaIngredients,
  isShowCategory = false,
  productFormulationType,
  showAddBlendBtn = true,
  productType,
  handleFactSheet,
}) => {
  const [primary, setPrimary] = useState('');
  const [secondary, setSecondary] = useState('');
  const [open, setOpen] = useState<boolean>(false);

  const { control, trigger } = useFormContext<AddNewFormulaFormType>();

  const { fields, remove, prepend } = useFieldArray({
    name: 'formulaIngredients',
    control,
  });

  const onRemove = (index: number) => {
    remove(index);
  };

  const onChange = (value: IngredientItemType, reason: AutocompleteChangeReason) => {
    if (reason === 'selectOption') {
      const isFound = fields?.some(({ ingredientId }) => ingredientId === value?.ingredientId);
      if (isFound) {
        Alert.warning(INGREDIENT_ALREADY_EXIST_TEX);
      } else {
        prepend({ ...value });
      }
    }
  };

  const handleBlendClick = async () => {
    const isValid = await trigger('formulaIngredients');
    if (isValid) {
      setOpen(!open);
    }
  };

  const noData = !(formulaIngredients?.length || ingredientsBlend.length);
  const showBlendBtn = formulaIngredients?.length && showAddBlendBtn;

  return (
    <Card sx={forInputLabelBg}>
      <Box mb={3} sx={flexCenterBetween}>
        <Typography variant="h6">{INGREDIENTS_TEXT}</Typography>
        {showAddBlendBtn ? (
          <Box sx={displayFlexAlignCenter}>
            {showBlendBtn ? (
              <Button variant="outlined" onClick={handleBlendClick} sx={{ mr: 2 }}>
                {ADD_BLEND_TEXT}
              </Button>
            ) : (
              <Fragment />
            )}
            <Button variant="contained" onClick={handleFactSheet} disabled={noData}>
              {VIEW_TEXT} {FACT_SHEET_TEXT}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {isShowCategory ? (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} lg={6}>
            <CategorySelector
              name="primary"
              disableClearable={false}
              title={PRIMARY_CATEGORY_TEXT}
              handleChange={({ value }: SelectType) => setPrimary(value)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CategorySelector
              primary={false}
              name="secondary"
              disableClearable={false}
              title={SECONDARY_CATEGORY_TEXT}
              handleChange={({ value }: SelectType) => setSecondary(value)}
            />
          </Grid>
        </Grid>
      ) : (
        <Fragment />
      )}
      <IngredientSelector
        isMultiple
        name="ingredients"
        title={SEARCH_TEXT}
        primaryId={primary}
        secondaryId={secondary}
        handleChange={onChange}
      />

      <Box pb={2} mt={2} pr={1} maxHeight="calc(100vh - 200px)" overflow={'auto'}>
        <IngredientBlends ingredientsBlend={ingredientsBlend} onBlendAdd={onBlendAdd} />

        <IngredientsCard
          xl={xl}
          fields={fields}
          onRemove={onRemove}
          isFactBox={isFactBox}
          productType={productType}
          productFormulationType={productFormulationType}
        />
      </Box>

      <NoIngredientFound noData={noData} />

      <AddBlendModal
        open={open}
        onBlendAdd={onBlendAdd}
        onClose={() => setOpen(!open)}
        ingredientsBlend={ingredientsBlend}
      />
    </Card>
  );
};

export default IngredientsList;
