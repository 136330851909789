import { Box, Tab, Tabs } from '@mui/material';
import { FC, Fragment, SyntheticEvent, useState } from 'react';
// components
import TabPanel from 'components/common/TabPanel';
import FormulaWeightNCost from '../../../FormulaDetail/components/FormulaWeightNCost';
import FormulaEstimatorDetail from '../../../FormulaDetail/components/FormulaEstimatorDetail';
// constants, contexts, graphql, interfaces
import { BottleCapType, ProductTypes } from 'generated/graphql';
import { FORMULA_COST_TABS, FormulaMetricsEnum } from 'constants/index';
import { PackageEstimatorProps, PackageNCostProps, TFormulaCosting } from 'interfaces';

const PackageNCost: FC<PackageNCostProps> = ({
  loading,
  bottleCap,
  productType,
  formulaCost,
  calculateCost,
  subProductType,
  productFormulationType,
  formulaIngredients,
}) => {
  const [activeTab, setActiveTab] = useState<FormulaMetricsEnum>(FormulaMetricsEnum.PACKAGE);

  const { type, name: productTypeName } = productType || {};
  const { name: subProductName } = subProductType || {};
  const { name: productFormulationName } = productFormulationType || {};

  const {
    cost,
    costPercentage,
    weightPercentage,
    weights,
    formulaIngredients: formulaIng,
  } = formulaCost || {};
  const {
    coMfgCost,
    mfgCost,
    capsulePrice,
    innerCapsulePrice,
    outerCapsulePrice,
    bottleCapacityInstance,
    bottlePrice,
    coatingCost,
    conversionCost,
    excipientCost,
    flavorCost,
    ingredientsCost,
    innerComponentCost,
    lohCost,
    neckband,
    per1000Cost,
    sandCoatingCost,
    sweetenerCost,
    syrupBaseCost,
  } = cost || {};
  const { bottleSize, fillPercentage, productSize } = bottleCapacityInstance || {};
  const { name } = productSize || {};

  const { lidSize, name: bottleName } = bottleSize || {};

  const bottleCost = {
    mfgCost: mfgCost ?? 0,
    coMfgCost: coMfgCost ?? 0,
  };

  const bottleFill = {
    fillPercentage: fillPercentage ?? 0,
  };

  const packageEstimator: PackageEstimatorProps = {
    name: bottleName || '--',
    lidSize: lidSize ?? 0,
    isChildResistant: bottleCap === BottleCapType.ChildResistant ? true : false,
    tabletSize: (type as ProductTypes) === ProductTypes.Tablet ? name || '--' : '',
  };

  const costing: TFormulaCosting = {
    ingredientInputs: formulaIngredients,
    formulaWeights: weights,
    formulaCostPercentage: costPercentage,
    formulaWeightsPercentage: weightPercentage,
    formulaCost: {
      bottlePrice,
      coatingCost,
      conversionCost,
      excipientCost,
      flavorCost,
      ingredientsCost,
      innerComponentCost,
      lohCost,
      neckband,
      per1000Cost,
      sandCoatingCost,
      sweetenerCost,
      syrupBaseCost,
    },
    formulaIngredients: formulaIng,
  };

  const tabHandler = (_: SyntheticEvent, tabValue: FormulaMetricsEnum) => {
    setActiveTab(tabValue);
  };

  return (
    <Fragment>
      <Box mb={2}>
        <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
          {FORMULA_COST_TABS?.map((item) => {
            const { name, value } = item;

            return <Tab value={value} key={value} label={name} iconPosition="start" />;
          })}
        </Tabs>
      </Box>

      <TabPanel activeTab={activeTab} value={FormulaMetricsEnum.PACKAGE}>
        <FormulaEstimatorDetail
          loading={loading}
          bottleCost={bottleCost}
          bottleFill={bottleFill}
          heading={productTypeName}
          capsulePrice={capsulePrice}
          calculateCost={calculateCost}
          packageEstimator={packageEstimator}
          outerCapsulePrice={outerCapsulePrice}
          innerCapsulePrice={innerCapsulePrice}
          title={`${subProductName ? `${subProductName},` : ''} ${productFormulationName}`}
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaMetricsEnum.COST}>
        <FormulaWeightNCost loading={loading} costing={costing} />
      </TabPanel>
    </Fragment>
  );
};

export default PackageNCost;
