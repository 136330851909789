import { FC, SyntheticEvent, useState } from 'react';
import { Box, Card, Tab, Tabs, Typography } from '@mui/material';
// components
import FormulaCostCard from './FormulaCostCard';
import TabPanel from 'components/common/TabPanel';
import FormulaWeightCard from './FormulaWeightCard';
import FormulaIngredientNutrients from './FormulaIngredientNutrients';
import IngCostContributionCard from './IngCostContributionCard';
import FormulaCostPercentageCard from './FormulaCostPercentageCard';
import FormulaWeightPercentageCard from './FormulaWeightPercentageCard';
// constants, interfaces
import { METRICS_TEXT } from 'constants/index';
import { FormulaWeightNCostProps } from 'interfaces';
import { FORMULA_INFO_TABS, FormulaTabEnum } from 'constants/tabs';

const FormulaWeightNCost: FC<FormulaWeightNCostProps> = ({ costing, loading }) => {
  const [activeTab, setActiveTab] = useState<FormulaTabEnum>(FormulaTabEnum.COST);
  const tabHandler = (_: SyntheticEvent, tabValue: FormulaTabEnum) => {
    setActiveTab(tabValue);
  };

  return (
    <Card>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6">{METRICS_TEXT}</Typography>
      </Box>
      <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
        {FORMULA_INFO_TABS?.map((item) => {
          const { name, value } = item;

          return <Tab value={value} key={value} label={name} iconPosition="start" />;
        })}
      </Tabs>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.COST}>
        <FormulaCostCard costing={costing} loading={loading} />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.WEIGHTS}>
        <FormulaWeightCard costing={costing} loading={loading} />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.COST_PERCENTAGE}>
        <FormulaCostPercentageCard costing={costing} loading={loading} />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.WEIGHTS_PERCENTAGE}>
        <FormulaWeightPercentageCard costing={costing} loading={loading} />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.NUTRIENTS_WEIGHTS}>
        <FormulaIngredientNutrients costing={costing} loading={loading} />
      </TabPanel>
      <TabPanel activeTab={activeTab} value={FormulaTabEnum.ING_COST_CONTRIBUTION}>
        <IngCostContributionCard costing={costing} loading={loading} />
      </TabPanel>
    </Card>
  );
};

export default FormulaWeightNCost;
