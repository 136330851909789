import { FC, Fragment } from 'react';
// component
import DataLoader from 'components/common/DataLoader';
// constants, interfaces, graphql
import { TFormulaLoading } from 'interfaces';
import RowView from 'components/common/RowView';

const IngCostContributionCard: FC<TFormulaLoading> = ({ costing, loading }) => {
  const { formulaIngredients } = costing || {};

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        formulaIngredients?.map((item, index) => {
          const { ingredient, ingCostContribution } = item || {};
          const { name } = ingredient || {};
          return (
            <Fragment>
              {loading ? (
                <DataLoader columns={12} rows={6} />
              ) : (
                <Fragment>
                  <RowView name={`${name}`} value={ingCostContribution ? `${ingCostContribution}%` : ''} />
                </Fragment>
              )}
            </Fragment>
          );
        })
      )}
    </Fragment>
  );
};

export default IngCostContributionCard;
